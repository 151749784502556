export default ({ app }, inject) => {
  inject('dropzoneOptions', (opt) => {
    return {
      ...opt,
      thumbnailWidth: 250,
      maxFilesize: 10,
      addRemoveLinks: true,
      headers: { Authorization: app.$auth.strategy.token.get() },
      dictDefaultMessage: 'Legen Sie Dateien hier ab um Sie hochzuladen',
      dictFallbackMessage: 'Ihr Browser unterstützt Drag&Drop Dateiuploads nicht',
      dictFallbackText: 'Benutzen Sie das Formular um Ihre Dateien hochzuladen',
      dictFileTooBig: 'Die Datei ist zu groß. Die maximale Dateigröße beträgt {{maxFileSize}}MB',
      dictInvalidFileType: 'Eine Datei dieses Typs kann nicht hochgeladen werden',
      dictResponseError: 'Der Server hat ihre Anfrage mit Status {{statusCode}} abgelehnt',
      dictCancelUpload: 'Hochladen abbrechen',
      dictCancelUploadConfirmation: null,
      dictRemoveFile: 'Datei entfernen',
      dictMaxFilesExceeded: 'Sie können keine weiteren Dateien mehr hochladen'
    }
  })
}
